import React from 'react';
import Calendar from './Calendar';
import { Button, Card, Grid, List, ListItem } from '@material-ui/core';
import calendarImg from '../../assets/images/calendar_bg.jpg';
import calendarImgXS from '../../assets/images/calendar_bg_xs.jpg';
import * as moment from 'moment';
import { DownArrowIcon } from '../../assets/customIcons';
import { withTrans } from '../../i18n/withTrans';
import { useHandleResize } from '../../utils';

const isBrowser = typeof window !== 'undefined';

const DateSelection = ({
  t,
  reservationDispatch,
  reservationState,
  ...props
}) => {
  let full_price = '0';
  let reduced_price = '0';
  let child_price = '0';
  let free_price = '0';
  let language = '0';
  let visitType = '0';

  if (isBrowser) {
    full_price = localStorage.getItem('full_price');
    reduced_price = localStorage.getItem('reduced_price');
    child_price = localStorage.getItem('child_price');
    free_price = localStorage.getItem('free_price');
    language = localStorage.getItem('language');
    visitType = localStorage.getItem('visitType');
  }
  const totalVisitor =
    JSON.parse(full_price) + JSON.parse(reduced_price) + JSON.parse(free_price);

  const onSelectSlot = (slot) => {
    const listTickets = [];
    if (full_price !== '0') {
      listTickets.push({
        visitorType: 'full_price',
        visitType: visitType,
        language: language,
        ...slot,
        price: visitType === '17' ? 26 * full_price : 23 * full_price
      });
    }

    if (reduced_price !== '0') {
      listTickets.push({
        visitorType: 'reduced_price',
        visitType: visitType,
        language: language,
        ...slot,
        price: visitType === '17' ? 26 * reduced_price : 16.5 * reduced_price
      });
    }

    if (child_price !== '0') {
      listTickets.push({
        visitorType: 'child_price',
        visitType: visitType,
        language: language,
        ...slot,
        price: visitType === '17' ? 26 * child_price : 10.0 * child_price
      });
    }

    if (free_price !== '0') {
      listTickets.push({
        visitorType: 'free_price',
        visitType: visitType,
        language: language,
        ...slot,
        price: 0
      });
    }

    reservationDispatch({
      selectedSlot: slot,
      ticketsList: listTickets
    });
  };

  const clickScroll = () => {
    const element = document.getElementsByClassName('slot-list')[0];
    const currentPosY = reservationState.scrollPos + 10;
    reservationDispatch({ scrollPos: currentPosY });
    element.scroll({ left: 0, top: currentPosY, behavior: 'smooth' });
  };

  const { width } = useHandleResize();

  return (
    <Card className="calendar-slot-wrapper">
      {/*      {width > 1609 ? (
                <Grid container>
                    <Grid item xs={7}>
                        <h2>{t('reservationPage.choose_date')}</h2>
                    </Grid>
                    <Grid item xs={5}>
                        <h2>{t('reservationPage.choose_slot')}</h2>
                    </Grid>
                </Grid>
            ) : (*/}
      <h2>{t('reservationPage.choose_date_and_slot')}</h2>
      {/* )}*/}
      <Grid
        container
        className="calendar-slot-box"
        style={{
          backgroundImage:
            width > 1610 ? `url(${calendarImg})` : `url(${calendarImgXS})`
        }}
      >
        <Grid item xs={7}>
          <Calendar
            reservationDispatch={reservationDispatch}
            reservationState={reservationState}
          />
        </Grid>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs={10}>
              <List className="slot-list">
                <div id="calendar_scroll">
                  {(reservationState.selectedDates || [])
                    .filter((el) => el.jauge >= totalVisitor)
                    .sort((a, b) => {
                      return a.date + a.heure - (b.date + b.heure);
                    })
                    .map((slot, index) => (
                      <ListItem key={index}>
                        <Card
                          className={
                            reservationState.selectedSlot === slot
                              ? 'selected-slot'
                              : ''
                          }
                          onClick={() => onSelectSlot(slot)}
                        >
                          <p>
                            {moment(slot.date)
                              .locale(props.language)
                              .format('dddd')}
                          </p>
                          <div className="slotCard">
                            <span>
                              {moment(slot.date)
                                .locale(props.language)
                                .format('DD MMMM')
                                .toLowerCase()}
                            </span>
                            <span>{`${slot.heure.substring(
                              0,
                              2
                            )}h${slot.heure.substring(3, 5)}`}</span>
                            <div className="slot-meta">
                              <span>
                                <span>{`${t(
                                  'reservationPage.duration'
                                )} : `}</span>
                                {t(
                                  `landingPage.infocard.${
                                    visitType === '17' ? 'time_night' : 'time'
                                  }`
                                )}
                              </span>
                              <span>
                                <span>{`${t(
                                  'reservationPage.availabilities'
                                )} : `}</span>
                                {slot.jauge}
                              </span>
                            </div>
                          </div>
                        </Card>
                      </ListItem>
                    ))}
                </div>
              </List>
            </Grid>
            {/* <Grid
              style={{
                display: "flex",
                justifyContent: "end",
                flexDirection: "column",
              }}
              item
              xs={2}
            >
              {(reservationState.selectedDates || []).length > 5 && (
                <div
                  style={{ color: "white", marginBottom: 10 }}
                  className="landing-readmore-link"
                  onClick={() => clickScroll()}
                >
                  <DownArrowIcon />
                </div>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      {/*    <div style={{float: "right", marginBottom: 5}}>
                <Button className="link-btn link-btn-secondary">{t('buttons.cancel')}</Button>
                <button className="btn-secondary" style={{ marginRight: 10 }}>{t('buttons.back')}</button>
                <button className="btn-secondary" >{t('buttons.validate')}</button>
            </div>*/}
      {width > 750 && (
        <>
          <p className="calendar-note">{t('reservationPage.calendar-note')}</p>
          <p className="calendar-note patrimonial-days-note">
            {t('reservationPage.patrimonial-days-note')}
          </p>
        </>
      )}
    </Card>
  );
};

export default withTrans(DateSelection);
