import React from 'react';
import {IconButton, Modal} from "@material-ui/core";
import {withTrans} from "../../i18n/withTrans";
import {navigate} from "../../../.cache/gatsby-browser-entry";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '40%',
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: 30,
};

const ModalConfirmOrderPayment = ({t, state, dispatch, messageTitle, message}) => {

    const onClose = () => {
        dispatch({ paymentSuccess: false });
        localStorage.clear();
        navigate('/')
    };

    return (
        <Modal
            disableEscapeKeyDown
            onClose={() => onClose()}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            open={state.paymentSuccess}
        >
            <div style={style}>
                <h2>{t('payment.success_title')}</h2>
                <p>{t('payment.success')}</p>
                <div>
                    <IconButton style={{float: 'right'}} onClick={onClose}>
                        Ok
                    </IconButton>
                </div>
            </div>
        </Modal>
    )
};

export default withTrans(ModalConfirmOrderPayment);
