import React from "react";
import { withTrans } from "../../i18n/withTrans";
import { IconButton, Modal } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  padding: 30,
  outline: 0,
  outlineStyle: "none",
};

const InformNotAvailabilitiesModal = ({ t, open, setOpen }) => {
  const onClose = () => {
    setOpen({ openModal: false });
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div style={style}>
        {/*<h2 className="modal-title">Copyright</h2>*/}
        <p>{t("calendar.withoutAvailabilities")}</p>
        <div style={{ position: "absolute", right: 10, top: 100 }}>
          <IconButton onClick={onClose}>Ok</IconButton>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(InformNotAvailabilitiesModal);
