import React from "react";
import {Box, Card, CardContent, IconButton} from "@material-ui/core";
import fullTicketImg from "../../assets/images/ticket-full.jpg";
import reducedTicketImg from "../../assets/images/ticket-reduced.jpg";
import childTicketImg from "../../assets/images/ticket-child.jpg";
import freeTicketImg from "../../assets/images/ticket-free.jpg";
import {withTrans} from "../../i18n/withTrans";
import moment from "moment";
import {MdClear} from "react-icons/md";

const OrderRecap = ({reservationDispatch, reservationState, t, ...props}) => {
    const visitType = localStorage.getItem('visitType');
    const language = localStorage.getItem('language');

    const [visibility, setVisibility] = React.useState("hidden");
    const styles = {
        visibility: `${visibility}`,
    };

    const setTicketImg = (visitorType) => {
        let ticketImg = '';
        switch (visitorType) {
            case 'full_price':
                ticketImg = fullTicketImg;
                break;
            case 'reduced_price':
                ticketImg = reducedTicketImg;
                break;
            case "child_price":
                ticketImg = childTicketImg;
                break;
            case 'free_price':
                ticketImg = freeTicketImg;
                break;
            default:
                ticketImg = fullTicketImg;
                break;
        }
        return ticketImg;
    }

    const visitorRender = (index, visitor) => {
        return (
            <Card key={index} className="cart-ticket"
                // onMouseEnter={() => setVisibility("visible")} onMouseLeave={() => setVisibility("hidden")}
            >
                <CardContent>
                    <div>
                        <img width={100} src={setTicketImg(visitor.visitorType)} alt={`Billet ${visitor.visitorType.includes('adult') ? 'adult' : visitor.visitorType.includes('young') ? 'young' : 'child'}`} />
                    </div>
                    <div className="ticket-info">
                        <p className="ticket-title">{t('landingPage.title2')} {t('landingPage.title3')}</p>
                        <p className="ticket-visit-type">{t(`visitTypeInfos.${visitor.status !== "old" ? visitType : reservationState.oldReservation.visitType}.title`)}</p>
                        <div className="ticket-meta-wrapper">
                            <div className="ticket-date-language">
                                <p className="ticket-date">
                                    {visitor.status !== 'old' ?
                                        moment(reservationState.selectedSlot.date).locale(props.language).format('dddd DD MMMM') :
                                        moment(reservationState.oldReservation?.selectedSlot.date).locale(props.language).format('dddd DD MMMM')} -
                                    {visitor.status !== 'old' ?
                                        `${reservationState.selectedSlot.heure.substring(0,2)}h${reservationState.selectedSlot.heure.substring(2)}` :
                                        `${reservationState.oldReservation.selectedSlot.heure.substring(0,2)}h${reservationState.oldReservation.selectedSlot.heure.substring(2)}`}
                                </p>
                                <p className="ticket-language">{language === 'F' ? `${t('reservationPage.languageChoice.french')}` : language === 'G' ? `${t('reservationPage.languageChoice.english')}` : `${t('reservationPage.languageChoice.spanish')}`}</p>
                            </div>
                            {/*<div className="ticket-visitor">
                                <p>{t('recap.visitor')} </p>
                                <p>{visitor.prenom} {visitor.nom}</p>
                            </div>*/}
                        </div>
                        <div className="recap-ticket-price">
                            <p>1 {visitor.status !== 'old' ? (
                                visitType === '17' ?
                                t('reservationPage.nbPersons.unique_price').toLowerCase() :
                                t(`reservationPage.nbPersons.${visitor.visitorType}`).toLowerCase()) :
                                reservationState.oldReservation.visitType === '17' ?
                                    t('reservationPage.nbPersons.unique_price').toLowerCase() :
                                    t(`reservationPage.nbPersons.${visitor.visitorType}`).toLowerCase()
                            }
                            </p>
                            <p>{visitor.price.toFixed(2)} €</p>
                        </div>
                    </div>
                    {/* <span className="delete-ticket" style={styles}>
                        <MdClear />
                    </span> */}
                </CardContent>
            </Card>
        )
    };

    return (
        <div className="recap-ticket-list">
            {reservationState.oldReservation &&
                reservationState.oldReservation.visitorsList.map((visitor, index) => (
                    visitorRender(index, visitor)
                ))}
         {reservationState.visitorsList && reservationState.visitorsList.map((visitor, index) => (
             visitorRender(index, visitor)
         ))}
        </div>

    )
};

export default withTrans(OrderRecap);
