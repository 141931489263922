import React from 'react';
import CartListCollapse from '../SlotSelection/CartListCollapse';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  CircularProgress
} from '@material-ui/core';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { withTrans } from '../../i18n/withTrans';

const MyVisitMobileRender = ({
  t,
  steps,
  onHandleVisitorNbChange,
  getStepContent,
  state,
  dispatch,
  onCancel,
  goBackStep,
  onValidateSlot,
  onShoppingContinue,
  setTicketImg,
  setVisibility,
  subtractNbVisitors,
  addNbVisitors,
  onDeleteTicket,
  styles,
  goBackToLanding
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    setIsLoading(false);
  }, [state.activeStep]);
  return (
    <div className="mobile_render_div">
      <div className="slot-selection-wrapper">
        {state.activeStep === 2 && (
          <h2 className="step3-title">{t('recap.title')}</h2>
        )}
        {state.activeStep === 3 && (
          <h2 className="step4-title">{t('payment.title')}</h2>
        )}
        <Stepper
          className={
            state.activeStep === 2 || state.activeStep === 3 ? 'recapSteps' : ''
          }
          alternativeLabel
          activeStep={state.activeStep}
        >
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{t(`steps.${step}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {state.activeStep !== 2 && (
          <CartListCollapse
            onHandleVisitorNbChange={onHandleVisitorNbChange}
            reservationState={state}
            reservationDispatch={dispatch}
            setTicketImg={setTicketImg}
            setVisibility={setVisibility}
            subtractNbVisitors={subtractNbVisitors}
            addNbVisitors={addNbVisitors}
            onDeleteTicket={onDeleteTicket}
            styles={styles}
          />
        )}
        {state.activeStep === 2 && (
          <div className="cart-total-price">
            <p>{t('cart.total')}</p>
            <p>{state.totalPrice} €</p>
            <span>{t('cart.vat-included')}</span>
          </div>
        )}
        {getStepContent(state.activeStep)}
        {state.activeStep === 0 || state.activeStep === 1 ? (
          <div className="btn-wrapper">
            <Button
              onClick={() => onCancel()}
              className="link-btn link-btn-secondary"
              style={{ marginRight: 10 }}
            >
              {t('buttons.cancel')}
            </Button>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '45vh',
                  left: '30vw'
                }}
              >
                <CircularProgress color="black" thickness={1} size={150} />
              </div>
            )}
            {!!(state.selectedDates || []).length && (
              <>
                <button
                  onClick={() => goBackStep()}
                  className="btn-secondary"
                  style={{ marginRight: 10 }}
                >
                  {t('buttons.back')}
                </button>
                <button
                  className="btn-secondary"
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    onValidateSlot();
                  }}
                >
                  {t('buttons.validate')}
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="recap-btn-wrapper">
            <button
              onClick={() =>
                state.payload && !state.payload?.error
                  ? goBackToLanding()
                  : onShoppingContinue()
              }
              className="primary"
            >
              <AiOutlineArrowLeft />
              {state.payload && !state.payload?.error
                ? t('buttons.back')
                : t('buttons.continue_shopping')}
            </button>
          </div>
        )}
        {state.activeStep === 2 && (
          <div className="recap-mobile-wrapper">
            <Button
              onClick={() => goBackToLanding()}
              className="link-btn"
              style={{ marginRight: 10 }}
            >
              {t('buttons.cancel')}
            </Button>
            <button
              className="primary final-btn"
              onClick={() => onValidateSlot()}
            >
              {t('buttons.final')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTrans(MyVisitMobileRender);
